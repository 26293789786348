import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 6",
    id: "LQTAMN2-U6-P62-E1",

    video: "",
    exerciseKey: "img/FriendsPlus/Page62/E1/Key/answerKey.png",
    component: DesignUnderLine,

    hideBtnFooter: true,
    questionImage: [
      // Row

      [
        {
          url: "img/FriendsPlus/Page62/62.jpg",
        },
      ],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
};

export default json;
