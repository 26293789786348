import T2 from "../../components/ExcerciseTypes/TypeIn/T2";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
const json = {
  1: {
    // Exercise num
    unit: "Unit 8",
    id: "LQTAMN2-U8-P79-E1",
    audio: "",
    video: "",
    component: UI,
    exerciseKey:
      "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page64/E1/Key/answerKey.png",
    // titleImage: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page50/E15/1.png",
    // titleQuestion: [{ num: '5', title: 'Watch or listen again and complete the Key Phrases with examples that the students talk about.', color: '#5196C7' }],
    hideBtnFooter: true,
    widthContent: "30%",
    questionImage: [
      [
        // Column
        {
          url: "img/FriendsPlus/page79/79.jpg",
        },
      ],
    ],

    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
};
export default json;
