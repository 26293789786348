import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
const json = {
  1: {
    // Exercise num
    unit: "Unit 5",
    id: "LQTAMN2-U5-P48-E1",
    // audio: "img/FriendsPlus/Page48/Audio/audio.e1.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page48/key.jpg",
    component: DesignUnderLine,
    // recorder: true,
    isAllowSubmit: false,
    totalInput: 1,
    questionImage: [
      // Row
      [
        // Column1
        {
          url: "img/FriendsPlus/Page48/E1/1.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page48/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page48/E1/3.jpg",
          input: 1,
        },
        {
          url: "img/FriendsPlus/Page48/E1/4.jpg",
          input: 2,
          isCorrect: true,
        },
        {
          url: "img/FriendsPlus/Page48/E1/5.jpg",
          input: 3,
        },
        {
          url: "img/FriendsPlus/Page48/E1/6.jpg",
          input: 4,
          isCorrect: true,
        },
        {
          url: "img/FriendsPlus/Page48/E1/7.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page48/E1/8.jpg",
        },
        {
          url: "img/FriendsPlus/Page48/E1/9.jpg",
          input: 5,
        },
        {
          url: "img/FriendsPlus/Page48/E1/10.jpg",
          input: 6,
          isCorrect: true,
        },
        {
          url: "img/FriendsPlus/Page48/E1/11.jpg",
          input: 7,
          isCorrect: true,
        },
        {
          url: "img/FriendsPlus/Page48/E1/12.jpg",
          input: 8,
        },
        {
          url: "img/FriendsPlus/Page48/E1/13.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page48/E1/14.jpg",
        },
        {
          url: "img/FriendsPlus/Page48/E1/15.jpg",
          input: 9,
          isCorrect: true,
        },
        {
          url: "img/FriendsPlus/Page48/E1/16.jpg",
          input: 10,
          isCorrect: true,
        },
        {
          url: "img/FriendsPlus/Page48/E1/17.jpg",
          input: 11,
        },
        {
          url: "img/FriendsPlus/Page48/E1/18.jpg",
          input: 12,
        },
        {
          url: "img/FriendsPlus/Page48/E1/19.jpg",
        },
      ],
    ],
  },
};
export default json;
